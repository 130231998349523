import { useState } from 'react'
import { Pagination, Mousewheel, Keyboard, Navigation } from 'swiper/modules';
import {
    FaCircleChevronLeft,
    FaCircleChevronRight,
    FaXmark
} from 'react-icons/fa6'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

const WSPGallery = ({ galleryImages }) => {

    const [slideNumber, setSlideNumber] = useState(0)
    const [openModal, setOpenModal] = useState(false)

    const handleOpenModal = (index) => {
        setSlideNumber(index)
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    // Previous Image
    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(galleryImages.length - 1)
            : setSlideNumber(slideNumber - 1)
    }

    // Next Image  
    const nextSlide = () => {
        slideNumber + 1 === galleryImages.length
            ? setSlideNumber(0)
            : setSlideNumber(slideNumber + 1)
    }

    return (
        <div className='w-full'>

            {openModal &&
                <div className='sliderWrap'>
                    <FaXmark className='btnClose text-lg' onClick={handleCloseModal} />
                    <FaCircleChevronLeft className='btnPrev text-lg' onClick={prevSlide} />
                    <FaCircleChevronRight className='btnNext text-lg' onClick={nextSlide} />
                    <div className='fullScreenImage'>
                        <img src={galleryImages[slideNumber].fileurl} alt='' />
                    </div>
                </div>
            }

            {/* <br />
      Current slide number:  {slideNumber}
      <br />
      Total Slides: {galleryImages.length}
      <br /><br /> */}


            <div className='w-full h-96 px-4'>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1.5}
                    navigation={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Pagination, Mousewheel, Keyboard, Navigation]}
                    className="mySwiper flex w-full justify-center items-center"
                >
                    <div className="absolute inset-0 bg-gradient-to-b rounded-md from-white via-transparent to-transparent opacity-50" />

                    {galleryImages?.map((image, index) => (
                        <SwiperSlide key={index} className="flex  rounded-md justify-center items-center">
                            <div className="flex justify-center  items-center" key={index}
                                onClick={() => handleOpenModal(index)}>
                                <img
                                    src={image.fileurl}
                                    alt={`car-${index}`}
                                    className=" object-cover on rounded-md  h-96 w-full"
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>


        </div>
    )
}

export default WSPGallery


{/* <div
                className='single'
                }
              >
                <img src={slide.fileurl} alt='' />
              </div> */}

