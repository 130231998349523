import React, { useState } from 'react'
import MaxWidthWrapper from '../component/MaxWidthWrapper';
import Navbar from '../component/Navbar'
import Footer, { FooterCard } from '../components/home/Footer'
import { LuMinus, LuPlus } from 'react-icons/lu'

import { BsChatSquareDots } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";


const Contact = () => {
  const [data, setData] = useState([
    {
      question: "How does the car auction process work?",
      answer: "The car auction process begins with sellers listing their vehicles on the platform. Buyers can browse through the listings, place bids, and follow the auction. At the end of the auction period, the highest bidder wins the car.",
      open: false
    },
    {
      question: "How can I list my car for auction?",
      answer: "To list your car for auction, you need to create an account on our platform. Once logged in, you can navigate to the 'Sell a Car' section and fill out the required details about your vehicle. You can then set a starting bid and auction duration.",
      open: false
    },
    {
      question: "What information should I provide about my car?",
      answer: "You should provide detailed information including the make, model, year of manufacture, mileage, condition, and any special features of the car. High-quality photos of the car from various angles are also recommended to attract potential buyers.",
      open: false
    },
    {
      question: "How do I place a bid on a car?",
      answer: "To place a bid, you need to be registered and logged in to the platform. Navigate to the auction listing of the car you're interested in, enter your bid amount, and submit it. You can monitor the auction to see if you are outbid and adjust your bid if needed.",
      open: false
    },
    {
      question: "Can I retract my bid once placed?",
      answer: "Once a bid is placed, it cannot be retracted. Make sure to review your bid carefully before submitting. If you have concerns or need assistance, please contact our support team.",
      open: false
    },
    {
      question: "What happens if I win the auction?",
      answer: "If you win the auction, you will receive a notification with instructions on how to proceed with the payment and vehicle pickup. You will need to complete the transaction within a specified period to finalize the purchase.",
      open: false
    },
    {
      question: "What payment methods are accepted?",
      answer: "We accept various payment methods including bank transfers, credit/debit cards, and other secure payment options. Details will be provided at the end of the auction.",
      open: false
    },
    {
      question: "How do I contact customer support?",
      answer: "If you have any questions or issues, you can contact our customer support team through the 'Contact Us' page on our website. You can reach us via email, phone, or live chat.",
      open: false
    },
    {
      question: "Is there a fee to list my car for auction?",
      answer: "Yes, there is a listing fee that varies depending on the auction duration and other factors. The fee details will be provided when you list your car. Please refer to our pricing page for more information.",
      open: false
    },
    {
      question: "How can I track the progress of my auction?",
      answer: "You can track the progress of your auction by logging into your account and navigating to the 'My Auctions' section. Here, you can view bids, watchlist activity, and auction status.",
      open: false
    }
  ])
  const changehandle = (index) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        open: !updatedData[index].open,
      };
      return updatedData;
    });
  };

  return (
    <>
      <MaxWidthWrapper>
        <Navbar />
      </MaxWidthWrapper>


      <MaxWidthWrapper className='mt-32 w-full font-inter gap-2 flex flex-col  overflow-hidden' >
        <div className='text-5xl text-richblue-200 font-bold'>
          Let's Connect
        </div>
        <div className='text-xl text-richblue-200/70 font-semibold'>
          Need support or have a question about Coast? We’re here to help.
        </div>

        <div className='flex justify-center items-center flex-wrap gap-8 mt-12'>

          <div className='font-inter flex flex-col h-[300px] w-[300px] border shadow-lg rounded-lg p-8 justify-center items-center gap-4'>

            <div className='flex justify-center items-center font-semibold text-richblue-200 text-2xl bg-indigo-200 rounded-full h-16 w-16 '>
              < BsChatSquareDots />
            </div>


            <div className='flex justify-center flex-col gap-2 items-center'>
              <p className='text-lg text-richblue-200 font-bold'>Chat Now</p>
              <p className='text-muted-foreground'>Right from this website</p>
            </div>

            <div className='h-px w-full bg-gray-200' />
            <div className='bg-orange-500 hover:bg-orange-500/80 rounded-lg flex justify-center cursor-pointer items-center w-full h-14 text-white'>
              start chat now
              <FaArrowRightLong className='ml-2' />

            </div>
          </div>


          <div className='font-inter flex flex-col h-[300px] w-[300px] border shadow-lg rounded-lg p-8 justify-center items-center gap-4'>

            <div className='flex justify-center items-center font-semibold text-richblue-200 text-2xl bg-indigo-200 rounded-full h-16 w-16 '>
              < BsChatSquareDots />
            </div>


            <div className='flex justify-center flex-col gap-2 items-center'>
              <p className='text-lg text-richblue-200 font-bold'> Email Us </p>
              <p className='text-muted-foreground'>From your Email app</p>
            </div>

            <div className='h-px w-full bg-gray-200' />
            <div className='flex justify-center cursor-pointer hover:underline font-bold items-center w-full h-14 text-richblue-200 '>

              support@domain.com

            </div>
          </div>
          <div className='font-inter flex flex-col h-[300px] w-[300px] border shadow-lg rounded-lg p-8 justify-center items-center gap-4'>

            <div className='flex justify-center items-center font-semibold text-richblue-200 text-2xl bg-indigo-200 rounded-full h-16 w-16 '>
              < BsChatSquareDots />
            </div>


            <div className='flex justify-center flex-col gap-2 items-center'>
              <p className='text-lg text-richblue-200 font-bold'>Call or Text Us</p>
              <p className='text-muted-foreground'>From your phone</p>
            </div>

            <div className='h-px w-full bg-gray-200' />
            <div className='flex justify-center font-bold hover:underline cursor-pointer items-center w-full h-14 text-richblue-200 '>
              +1 (623) 98300383

            </div>
          </div>

        </div>

      </MaxWidthWrapper>


      <div class="py-10  sm:py-16 lg:py-24">
        <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div class="max-w-2xl mx-auto text-center">
            <h2 class="text-3xl font-bold leading-tight text-richblue-200 sm:text-4xl lg:text-5xl">Questions & Answers</h2>
            <p class="max-w-xl mx-auto mt-4  text-xl text-richblue-200/70 font-semibold">Explore the common questions and answers about Celebration</p>
          </div>

          <div class="flex flex-col gap-4 mt-20">


            {
              data.map((faq, index) => {
                return <div key={index} className='text-gray-800  font-inter'>
                  <div className=' m-2 p-2 rounded-md flex items-center'>
                    <div className='min-w-[90%]'>
                      <div className=' font-semibold font-inter '>

                        {faq.question}
                      </div>

                      <div className={`mt-2 text-sm text-muted-foreground ${faq.open ? 'transition-all duration-700 ease-in-out max-h-[200vh]' : 'max-h-0 overflow-hidden transition-all duration-500 ease-in-out'}`}>
                        {faq.answer}
                      </div>

                    </div>
                    <div
                      className='text-richyellow-10 min-w-[10%] text-3xl cursor-pointer'
                      onClick={() => changehandle(index)}
                    >
                      {
                        faq.open ? <LuMinus /> : <LuPlus />

                      }


                    </div>
                  </div>
                  <div className='h-px w-full bg-gray-200' />
                </div>
              })
            }



          </div>

          <div class="flex items-center justify-center mt-12 md:mt-20">
            <div class="px-8 py-4 text-center bg-richblue-200 rounded-full">
              <p class="text-gray-50">Didn’t find the answer you are looking for? <a href="#" title="" class="text-indigo-300 transition-all duration-200 hover:text-indigo-400 focus:text-indigo-400 hover:underline">Contact our support</a></p>
            </div>
          </div>
        </div>
      </div>



      <FooterCard />
      <Footer />
    </>
  )
}

export default Contact


