import React from 'react'
import MaxWidthWrapper from '../../component/MaxWidthWrapper';
import CarBodyCard from './CarBodyCard';
import { Button } from '../../component/ui/button';
import { cars } from "./FoundCar"
const carBodyTypes = [
    "Sedan",
    "Hatchback",
    "SUV",
    "Coupe",
    "Convertible",
    "Truck",
    "Van",
    "Wagon",
    "Crossover",
    "Roadster",
    "Minivan",
    "Pickup"
]


const BodyTypeCars = () => {
    return (
        <MaxWidthWrapper>

            <div className='flex items-center gap-6 flex-wrap'>
                {
                    carBodyTypes.map((bodyType) => {
                        return <Button variant='secondary' size='sm' className='bg-richblue-50 hover:bg-richblue-50/90 rounded-full' key={bodyType} >
                            {bodyType}
                        </Button>
                    })
                }
            </div>
            <div className='grid mt-10 gap-6  grid-cols-1  sm:grid-cols-3'>
                {
                    cars.map((car) => {
                        return <CarBodyCard car={car} endTime={car.endTime} />
                    })
                }
            </div>

        </MaxWidthWrapper>
    )
}

export default BodyTypeCars;