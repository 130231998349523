import React, { createContext, useContext, useState, Suspense } from 'react';
import MaxWidthWrapper from '../component/MaxWidthWrapper';
import Navbar from '../component/Navbar';
import { Button } from '../component/ui/button';
import Filter from '../components/home/Filter';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { IoMdSearch } from "react-icons/io";
import { useSelector } from 'react-redux';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../component/ui/form';
import CarCards from '../components/home/CarCards';

import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../component/ui/sheet"
import { IoFilterSharp } from "react-icons/io5";
import {CarsSkeleton} from '../component/CarSkeleton';

// Context for Search
const SearchContext = createContext();

export const useSearch = () => useContext(SearchContext);

const BrowseCars = React.lazy(() => import('../components/home/BrowseCars'));

const searchSchema = z.object({
  search: z.string().min(3, { message: 'Search query cannot be empty' }).max(100, { message: 'Search query is too long' }),
});

export const SearchComponent = () => {
  const { setSearchValue } = useSearch();
  const form = useForm({
    resolver: zodResolver(searchSchema),
    defaultValues: {
      search: '',
    },
  });



  const onSubmit = (data) => {
    setSearchValue(data.search);
    console.log('Search Query:', data.search);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
        <FormField
          control={form.control}
          name="search"
          render={({ field }) => (
            <FormItem>
              <FormLabel htmlFor="search" className="sr-only">
                Search
              </FormLabel>
              <div className="relative">
                <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
                  <IoMdSearch className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                </div>
                <FormControl>
                  <input
                    type="search"
                    id="search"
                    placeholder="Search auction cars..."
                    className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                    {...field}
                  />
                </FormControl>
                <Button type="submit" className='absolute right-2.5 bottom-2' variant='btn'>
                  <IoMdSearch className='mx-2' /> Search
                </Button>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};

const BrowseAuctions = () => {
  const [searchValue, setSearchValue] = useState('');
  const { user } = useSelector((state) => state.profile);

  return (
    <SearchContext.Provider value={{ searchValue, setSearchValue }}>
      <div className="min-h-screen font-inter">
        <MaxWidthWrapper>
          <Navbar />
        </MaxWidthWrapper>

        <div className="w-full">

          <div className='grid grid-rows-8 gap-2'>



            <div className="flex gap-4 row-span-6">

              <Sheet>

                <SheetContent side='left'>
                  <Filter />
                </SheetContent>


                {/* <div className="sticky sticky-element hidden md:block top-0 h-screen">
               
              </div> */}

                <div className="flex-1 flex-col pt-24 min-h-screen h-full  p-2">


                  <div className="h-20 bg-white z-10 sticky top-16 row-span-1 grid grid-cols-8 justify-center items-center  w-full">

                    <div className="col-span-1 flex justify-center">
                      <SheetTrigger asChild >

                        <Button variant="btn" size='sm' className='w-10 font-bold text-xl px-0'><IoFilterSharp /></Button>
                      </SheetTrigger>
                      
                    </div>


                    <div className="col-span-2 text-gray-800 font-bold text-4xl flex justify-center">
                      Find your Car
                    </div>

                    <div className="col-span-5 w-[90%] h-full flex justify-center items-center">
                      <SearchComponent />
                    </div>

                  </div>



                  <div className='w-full h-full flex-1 justify-center min-h-screen items-center'>
                    <Suspense fallback={<CarsSkeleton/>}>
                      <BrowseCars />
                    </Suspense>

                  </div>

                </div>
              </Sheet>
            </div>
          </div>
        </div>
      </div>
    </SearchContext.Provider>
  );
};

export default BrowseAuctions;
