import React from 'react'
import MaxWidthWrapper from '../component/MaxWidthWrapper';
import Navbar from '../component/Navbar'
import Img5 from '../assests/img5.avif'
import Img7 from '../assests/img7.svg'
import Footer, { FooterCard } from '../components/home/Footer';
const About = () => {
  return (
    <div className='max-w-screen overflow-hidden'>
      <MaxWidthWrapper>
        <Navbar />
      </MaxWidthWrapper>


      <MaxWidthWrapper className='mt-32 w-full font-inter flex justify-center overflow-hidden items-center'>

        <div className='text-5xl text-richblue-200 w-2/3  text-center font-bold'>

          Exploring Our Vision for a Seamless Car Auction Experience

        </div>

      </MaxWidthWrapper>

      <div className='w-screen max-w-screen overflow-hidden'>
        <img src={Img5} className='max-w-full w-full' alt="" />
      </div>

      <MaxWidthWrapper className='mt-32  overflow-hidden w-full font-inter flex flex-col space-y-20 justify-center items-center'>
        
        <div className='flex w-2/3 gap-4 flex-col justify-center items-start'>
          <div className='text-5xl text-richblue-200  text-center font-bold'>

            About Us

          </div>
          <div>
            Welcome to Openlane, your premier online platform for vehicle auctions across Europe. Established with a passion for innovation and transparency in the automotive industry, we are dedicated to connecting buyers and sellers through a seamless, secure, and efficient online auction experience.

          </div>
        </div>


        <div className='flex w-2/3 gap-4 flex-col justify-center items-start'>
          <div className='text-5xl text-richblue-200  text-center font-bold'>

            Our Mission

          </div>
          <div>
            At BidDrive, our mission is to revolutionize the way vehicles are bought and sold across Europe. We provide an intuitive and user-friendly platform that allows buyers to access a wide range of vehicles, from everyday cars to luxury models, all in one place. Sellers benefit from our extensive network and cutting-edge auction technology, ensuring that they reach the right buyers quickly and effectively.

          </div>
        </div>

        <div className='flex w-2/3 gap-4 flex-col justify-center items-center'>
  
          <div className='text-5xl text-richblue-200  text-center font-bold'>

            Out Team
          </div>

          <div className='w-8/12 mt-10'>
            <img src={Img7} className='w-full' alt="" />
          </div>
        </div>

      </MaxWidthWrapper>

      < FooterCard />
      < Footer />

    </div>)
}

export default About;