import React, { useState } from 'react';
import MaxWidthWrapper from '../../component/MaxWidthWrapper';
import { Input } from '../../component/ui/input';
import { Button, buttonVariants } from '../../component/ui/button';
import { RxCross2 } from "react-icons/rx";
import { Icons } from "../../assests/Icons"
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import Filter from './Filter';
import CarCards from './CarCards';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { DivideCircleIcon } from 'lucide-react';

export const cars = [
    {
        id: 1,
        name: 'Toyota Corolla',
        description: '2018 Model, Excellent Condition',
        mileage: '25,000 miles',
        price: '$15,000',
        images: [
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/156405/xuv-3xo-exterior-right-front-three-quarter-33.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/142515/elevate-exterior-right-front-three-quarter-21.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80'
        ], // Replace with actual image URL
        endTime: new Date(Date.now() + 3600 * 1000), // 1 hour from now
    },
    {
        id: 2,
        name: 'Honda Civic',
        description: '2020 Model, Low Mileage',
        mileage: '12,000 miles',
        price: '$18,500',
        images: [
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/156405/xuv-3xo-exterior-right-front-three-quarter-33.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/142515/elevate-exterior-right-front-three-quarter-21.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80'
        ], // Replace with actual image URL
        endTime: new Date(Date.now() + 7200 * 1000), // 2 hours from now
    },
    {
        id: 3,
        name: 'Ford Mustang',
        description: '2015 Model, Powerful Engine',
        mileage: '40,000 miles',
        price: '$25,000',
        images: [
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/156405/xuv-3xo-exterior-right-front-three-quarter-33.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/142515/elevate-exterior-right-front-three-quarter-21.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80'
        ],// Replace with actual image URL
        endTime: new Date(Date.now() + 10800 * 1000), // 3 hours from now
    },
    {
        id: 4,
        name: 'Ford Mustang',
        description: '2015 Model, Powerful Engine',
        mileage: '40,000 miles',
        price: '$25,000',
        images: [
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/156405/xuv-3xo-exterior-right-front-three-quarter-33.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/142515/elevate-exterior-right-front-three-quarter-21.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80'
        ], // Replace with actual image URL
        endTime: new Date(Date.now() + 10800 * 1000), // 3 hours from now
    },
    {
        id: 5,
        name: 'Toyota Corolla',
        description: '2018 Model, Excellent Condition',
        mileage: '25,000 miles',
        price: '$15,000',
        images: [
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/156405/xuv-3xo-exterior-right-front-three-quarter-33.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/142515/elevate-exterior-right-front-three-quarter-21.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80'
        ], // Replace with actual image URL
        endTime: new Date(Date.now() + 3600 * 1000), // 1 hour from now
    },
    {
        id: 6,
        name: 'Honda Civic',
        description: '2020 Model, Low Mileage',
        mileage: '12,000 miles',
        price: '$18,500',
        images: [
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/156405/xuv-3xo-exterior-right-front-three-quarter-33.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/142515/elevate-exterior-right-front-three-quarter-21.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80',
            'https://imgd.aeplcdn.com/664x374/n/cw/ec/175951/slavia-exterior-right-front-three-quarter-5.jpeg?isig=0&q=80'
        ],// Replace with actual image URL
        endTime: new Date(Date.now() + 7200 * 1000), // 2 hours from now
    },
   
];

const formSchema = z.object({
    keyword: z.string().optional(),
    make: z.string().optional(),
    model: z.string().optional(),
    fromyear: z.string()
        .optional()
        .refine(value => value === undefined || !isNaN(Number(value)), {
            message: "From year must be a valid year.",
        }),
    tillyear: z.string()
        .optional()
        .refine(value => value === undefined || !isNaN(Number(value)), {
            message: "Till year must be a valid year.",
        }),
    price: z.string()
        .optional()
        .refine(value => value === undefined || !isNaN(Number(value)), {
            message: "Price must be a valid number.",
        }),
    mileage: z.string()
        .optional()
        .refine(value => value === undefined || !isNaN(Number(value)), {
            message: "Mileage must be a valid number.",
        }),
    bodyType: z.string().optional(),
}).refine((data) => {
    if (data.fromyear && data.tillyear) {
        return Number(data.tillyear) > Number(data.fromyear);
    }
    return true; // If either is not present, skip the comparison
}, {
    message: "Till year must be greater than from year.",
    path: ["tillyear"],
});




export const FilterButton = ({ children, onRemove }) => {
    return (
        <>
            <Button
                variant="ghost"
                className="bg-richblue-50 flex gap-2 pr-[5px] hover:bg-richblue-50 justify-between items-center rounded-full"
            >
                <p>{children}</p>
                <div className='bg-richblue-100 h-8 w-8 flex justify-center hover:bg-richblue-100/90 cursor-pointer items-center rounded-full' onClick={onRemove}>
                    <Icons.cross className="text-white h-6 w-6 " />
                </div>
            </Button>
        </>
    );
};

const FoundCar = () => {
    const [fromYearSelected, setFromYearSelected] = useState(1986);
    // Use useForm hook
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            keyword: "",
            make: "",
            model: "",
            fromyear: String(fromYearSelected),
            tillyear: String(fromYearSelected + 1),
            price: '',
            mileage: '',
            bodyType: "",
        },
    });
    const heading = "24 Cars Found"
    const currentYear = new Date().getFullYear();

    const years = Array.from({ length: currentYear - 1986 + 1 }, (_, i) => 1986 + i);
    // Function to handle form submission
    const onSubmit = (data) => {
        console.log(data); // This will log all form values
    };

    
    // Use useForm hook
    const [currentPage, setCurrentPage] = useState(1);
    const carsPerPage = 4;

    // Calculate the index range for the current page
    const indexOfLastCar = currentPage * carsPerPage;
    const indexOfFirstCar = indexOfLastCar - carsPerPage;
    const currentCars = cars.slice(indexOfFirstCar, indexOfLastCar);

    // Calculate total pages
    const totalPages = Math.ceil(cars.length / carsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (

        <div className='overflow-x-hidden'>

            <div className='grid grid-cols-1 sm:grid-cols-3 font-inter gap-6 '>


                <div className="h-full flex col-span-1 items-center">
                 <Filter />
                </div>

                <div className='col-span-2 mt-10'>

                    <div className='text-5xl  font-medium'>
                        {heading}
                    </div>

                    <div className='flex gap-2 flex-wrap items-center mt-4'>
                        <FilterButton>Price</FilterButton>
                        <FilterButton>Make</FilterButton>
                        <FilterButton>Model</FilterButton>
                        <FilterButton>Year</FilterButton>

                    </div>

                    <div>
                        <div className="overflow-x-auto mt-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {currentCars.map((car) => (
                                    <CarCards key={car.id} car={car} endTime={car.endTime} />
                                ))}
                            </div>
                        </div>

                        {/* Pagination Controls */}
                        <div className="mt-4 flex justify-between space-x-2">
                            <Button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="flex items-center gap-2"
                            >
                                <MdOutlineKeyboardDoubleArrowLeft className='text-lg' />
                                <p>Prev</p>


                            </Button>

                            <Button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="flex items-center gap-2"
                            >
                                <p> Next </p>

                                <MdOutlineKeyboardDoubleArrowRight className='text-lg' />

                            </Button>
                        </div>
                    </div>


                </div>

            </div>


        </div>
    );
};

export default FoundCar;
