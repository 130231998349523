import React from 'react'
import { Icons } from '../../assests/Icons'
import Img6 from '../../assests/img6.svg'
import MaxWidthWrapper from '../../component/MaxWidthWrapper'
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { Button } from '../../component/ui/button'
const Footer = () => {
    return (
        < >
            <MaxWidthWrapper className='h-screen'>



            </MaxWidthWrapper>

        </>
    )
}

export default Footer

export const FooterCard = () => {
    return (
        <>
            <MaxWidthWrapper className='mt-32 font-inter'>
                <div
                    className="bg-cover bg-center rounded-lg p-16 flex flex-col gap-2 bg-no-repeat h-96 w-full"
                    style={{ backgroundImage: `url(${Img6})` }}
                >

                    <div className='text-white text-5xl font-bold'>
                        Drive your Dream <br /> Car
                    </div>

                    <div className='text-white text-lg mt-2 font-semibold'>
                        Your Next Car is Just a Bid Away
                    </div>

                    <div className='mt-4'>
                        <div class="mt-6 flex max-w-md gap-x-4">
                            <label for="email-address" class="sr-only">Email address</label>
                            <input id="email-address" name="email" type="email" autocomplete="email" required class="min-w-0 flex-auto rounded-md border-0 bg-white px-3.5 py-2 text-richblue-100 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 p-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6" placeholder="Enter your email" />
                            <Button variant='white' >
                                Subscribe
                            </Button>
                        </div>

                    </div>


                    <div className='mt-6 flex items-center gap-4'>
                        <div className='text-white  text-normal flex items-center gap-2 font-semibold font-inter'>
                            <IoCheckmarkCircleSharp />  <p className='text-white/80'>
                                Start Bidding in minutes
                            </p>
                        </div>
                        <div className='text-white  text-normal flex items-center gap-2 font-semibold font-inter'>
                            <IoCheckmarkCircleSharp />  <p className='text-white/80'>
                                Verified Veihicle Histories
                            </p>
                        </div>
                        <div className='text-white  text-normal flex items-center gap-2 font-semibold font-inter'>
                            <IoCheckmarkCircleSharp />  <p className='text-white/80'>
                                Nationwide Shipping Available
                            </p>
                        </div>
                    </div>


                </div>

            </MaxWidthWrapper>
        </>
    )
}