import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Helper function to get the token from cookies
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}

// Base query with token in headers
const baseQuery = fetchBaseQuery({
  baseUrl: "https://bid-drive.com/",

  credentials: "include",

  prepareHeaders: (headers) => {

    const token = getCookie("token");

    // If a token exists, add it to the headers
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

// Create API slice
export const listingApiSlice = createApi({
  reducerPath: "api",
  baseQuery,

  endpoints: (builder) => ({
    createListing: builder.mutation({
      query: ({ formData, step }) => ({
        url: `api/v2/create_listing/${step}`,
        method: "POST",
        body: formData,
      }),
    }),

    DraftListings: builder.query({
      query: () => "api/v2/get_draft_listing",
    }),

    ListingById: builder.query({
      query: (id) => `api/v2/get_listing_by_id/${id}`,
    }),

    updateStatus: builder.mutation({
      query: (formData) => ({
        url: `api/v2/change_status`,
        method: "POST",
        body: formData,
      }),
    }),

    saveForLater: builder.mutation({
      query: ({ userId, carId }) => ({
        url: `api/save_for_later/${userId}/${carId}`,
        method: "POST",
      }),
    }),

    createBidding: builder.mutation({
      query: ({ bidAmount, userId, carId }) => ({
        url: `api/create_bidding/${userId}/${carId}`,
        method: "POST",
        body: { bidAmount },
      }),
    }),

    UserBids: builder.query({
      query: ({ carId, userId }) => `api/user_bids/${userId}/${carId}`,
    }),

 
    GetUsersBids: builder.query({
      query: ({userId }) => `api/get_users_detail/${userId}`,
    }),



    withDrawalBidding: builder.mutation({
      query: ({  userId, carId }) => ({
        url: `api/withdrawal_bidding/${userId}/${carId}`,
        method: "POST",
      }),
    }),



  }),
});

// Export the hooks for components to use
export const {
  useCreateListingMutation,
  useDraftListingsQuery,
  useListingByIdQuery,
  useUpdateStatusMutation,
  useSaveForLaterMutation,
  useCreateBiddingMutation,
  useUserBidsQuery,
  useLazyUserBidsQuery,
  useGetUsersBidsQuery,
  useWithDrawalBiddingMutation
} = listingApiSlice;


