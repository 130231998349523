import React, { useState, useEffect } from 'react';
import MaxWidthWrapper from '../../component/MaxWidthWrapper';
import Navbar from '../../component/Navbar';
import { useListingByIdQuery, useSaveForLaterMutation, useCreateBiddingMutation, useLazyUserBidsQuery } from "../../slices/apiSlices/carListingApiSlice";
import { toast } from '../../component/ui/use-toast'
import { useParams, useNavigate } from 'react-router-dom';
import WPSGallery from '../../component/WPSGallery';
import { IoMdShareAlt } from "react-icons/io";
import { FaHeart } from "react-icons/fa";
import { Button } from '../../component/ui/button';
import { formatPrice } from '../../lib/utils';
import { Input } from '../../component/ui/input';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "../../component/ui/form";
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton, } from 'react-share';
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaTelegram } from "react-icons/fa6";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../component/ui/dialog"
import { Separator } from "../../component/ui/separator"
import { useSelector, useDispatch } from 'react-redux';
import { listenToHighestBid } from '../../slices/fireBaseSlice';
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "../../component/ui/hover-card"
import { useFetchAndSaveUserBids } from '../../slices/listingSlice';
import { IoIosArrowBack } from "react-icons/io";
import { emitNewHighestBid } from '../../slices/socketSlice'
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../component/ui/tabs"

const biddingSchema = z.object({
    bidPrice: z.string().min(1, "Bid price must be greater than 0").refine(value => parseInt(value) > 0, "Bid price must be positive")
});

export const AuctionHistoryTable = ({ bidHistory }) => {
    const [visibleBids, setVisibleBids] = useState(3); // Initially show 3 bids

    if (!bidHistory || bidHistory.length === 0) {
        return <p>No auction history available.</p>;
    }

    // Function to show more bids
    const showMoreBids = () => {
        setVisibleBids(visibleBids + 3); 
    };

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
                <thead>
                    <tr className="text-left">
                        <th className="py-2 px-4 border-b">Username</th>
                        <th className="py-2 px-4 border-b">Bid Amount</th>
                        <th className="py-2 px-4 border-b">Bid Time</th>
                    </tr>
                </thead>
                <tbody>
                    {bidHistory.slice(0, visibleBids).map((bid, index) => (
                        <tr className="font-inter font-medium text-sm" key={index}>
                            <td className="py-2 px-4 text-muted-foreground border-b">{bid.user}</td>
                            <td className="py-2 px-4 font-semibold border-b">{`$${bid.bidAmount}`}</td>
                            <td className="py-2 px-4 text-muted-foreground border-b">{new Date(bid.bidTime).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {visibleBids < bidHistory.length && (
                <div className="text-right mt-4">
                    <Button
                    variant='outline'
                        onClick={showMoreBids}
                       
                    >
                        See More
                    </Button>
                </div>
            )}
        </div>
    );
};



export const ShareOptions = () => {
    const shareUrl = window.location.href;
    const title = 'Check out this awesome content!';

    return (
        <div className='flex flex-col gap-4 w-full text-richblue-200 rounded-md'>

            <div className='flex gap-4 items-center'>
                <FacebookShareButton url={shareUrl} quote={title}>
                    < FaFacebook size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={title}>
                    <FaTwitter size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={title}>
                    <IoLogoWhatsapp size={32} round />
                </WhatsappShareButton>
                <TelegramShareButton url={shareUrl} title={title}>
                    <FaTelegram size={32} round />
                </TelegramShareButton>
            </div>

            {/* Instagram does not support direct sharing links from web */}
        </div>
    );
};

const formatDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString);
        const options = { weekday: 'long', hour: '2-digit', minute: '2-digit' };
        const formattedEndTime = date.toLocaleString('en-GB', options);
        return formattedEndTime;
    } else {
        return "Invalid"
    }
}

export const Features = ({ vehicleInformation }) => {

    if (!vehicleInformation) {
        return <p>No vehicle information available.</p>;
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
                <thead>
                    <tr className=" ">
                        <th className="py-2 text-left px-4 border-b">Feature</th>
                        <th className="py-2 text-right px-4 border-b">Details</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(vehicleInformation)?.map(([feature, detail]) => (
                        <tr key={feature}>
                            <td className="py-2 px-4 border-b text-left capitalize text-muted-foreground font-medium">{feature.replace('_', ' ')}</td>
                            <td className="py-2 px-4 border-b text-right font-semibold">{detail}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const CarDetails = () => {

    const { id } = useParams();
    const { istoken, user } = useSelector((state) => state.profile) || {};
    const { data, error, isLoading, isSuccess } = useListingByIdQuery(id);
    const [saveForLater, { isLoading: loading }] = useSaveForLaterMutation();
    const [createBidding, { isLoading: bidLoading }] = useCreateBiddingMutation();
    const [trigger, { data: userBidsData }] = useLazyUserBidsQuery();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isVisible, setVisible] = useState(false)
    const [isSaved, setSaved] = useState(false)
    const { isLoading: savedLoading, error: savedError } = useFetchAndSaveUserBids({ userId: user?.id });
    const { savedCars, biddingHistory } = useSelector((state) => state.listing);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(data?.Listing?.startTime, data?.Listing?.endTime));
    const [socketData, setSocketData] = useState()


    const goBack = () => {
        navigate(-1);
    };

    const highestBid = useSelector((state) => state.bid.highestBid);
    // const priceLoader = useSelector((state) => state.bid.loading);
    // const priceError = useSelector((state) => state.bid.error);


    useEffect(() => {
        if (savedCars) {
            if (Array.isArray(savedCars)) {
                const isCarSaved = savedCars.find(savedCar => savedCar.carId === id);
                setSaved(Boolean(isCarSaved));
            }
        }
        // console.log(savedCars)
        // console.log(isSaved)
    }, [savedCars]);


    useEffect(() => {
        if (id && user?.id) {
            trigger({ carId: id, userId: user?.id }); // Manually trigger the fetch
        }
    }, [isSuccess]);




    useEffect(() => {
        if (id) {
            const unsubscribe = dispatch(listenToHighestBid({ carId: id }));

            return () => {
                if (unsubscribe) {
                    unsubscribe();
                }
            };
        }
    }, [id, dispatch]);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(data?.Listing?.startTime, data?.Listing?.endTime));
        }, 1000);

        return () => clearInterval(timer);
    }, [data?.Listing?.endTime]);


    function calculateTimeLeft(startTime, endTime) {
        const now = new Date();
        const start = new Date(startTime);
        const end = new Date(endTime);
        let timeLeft = {};

        if (now < start) {
            // Auction has not started yet
            const difference = start - now;
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
                expired: false,
                status: 'starting',
            };
        } else if (now < end) {
            // Auction is ongoing
            const difference = end - now;
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
                expired: false,
                status: 'ongoing',
            };
        } else {
            // Auction has ended
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                expired: true,
                status: 'expired',
            };
        }

        return timeLeft;
    }

    const form = useForm({
        resolver: zodResolver(biddingSchema),
    });


    const SaveForLaterHandler = async () => {

        try {

            if (!user || !istoken) {
                navigate('/sign-in')
            }

            const response = await saveForLater({ userId: user?.id, carId: id })


            setSaved(!isSaved)

            toast({
                title: "Saved For Later",
                description: response.data.message,
                status: "success",
            });


        } catch (error) {
            toast({
                title: "Error",
                description: error?.data?.message || "Failed to save for later",
                status: "error",
                variant: "destructive",
            });
        }


    }


    const onSubmit = async (getData) => {
        if (!user || !istoken) {
            navigate('/sign-in');
        }
        setSocketData({

        })
        try {


            if (new Date(data?.Listing?.endTime) < new Date()) {
                form.setError('bidPrice', {
                    type: 'manual',
                    message: "This auction has ended. Please check back later.",
                });
                return;
            }

            if (new Date(data?.Listing?.startTime) > new Date()) {
                form.setError('bidPrice', {
                    type: 'manual',
                    message: "This auction has not Started yet. Please check back later.",
                });
                return;
            }


            const minimumBidAmount = data?.Listing?.highestBid !== undefined
                ? data?.Listing?.highestBid + (data?.Listing?.minimumBidDifference || 0)
                : (data?.Listing?.price || 0) + (data?.Listing?.minimumBidDifference || 0);

            if (getData.bidPrice < minimumBidAmount) {
                form.setError('bidPrice', {
                    type: 'manual',
                    message: `Bid price must be at least ${formatPrice(minimumBidAmount)}`,
                });
                return;
            }

            const response = await createBidding({
                bidAmount: getData.bidPrice,
                userId: user?.id,
                carId: id,
            }).unwrap();


            // Debug log for response
            console.log("Response:", response);

            toast({
                title: "Bid Placed Successfully",
                description: response.data?.message || "Your bid was placed successfully.",
                status: "success",
                duration: 3000, // Optional duration
                isClosable: true, // Optional closable option
            });

            const title = data?.Listing?.name;
            const carId = id;
            const bidAmount = getData.bidPrice;
            const image = data?.Listing?.images[0].fileurl || '';

            emitNewHighestBid(title, carId, bidAmount, image);

            dispatch(listenToHighestBid(id));

            form.reset();
        } catch (err) {
            form.reset();

            if (err?.data?.message) {
                form.setError('bidPrice', {
                    type: 'manual',
                    message: err.data.message,
                });
            }
        }
    };

    return (
        <>
            <MaxWidthWrapper>
                <Navbar />
            </MaxWidthWrapper>

            <MaxWidthWrapper className='mt-32'>
                <div className='p-4'>
                    <Button variant='btn' onClick={goBack} className='font-bold p-0  h-10 w-10 rounded-md'><IoIosArrowBack className='text-lg ' /></Button>
                </div>
                <div className='p-4 relative flex items-center justify-between'>
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button variant='secondary' onClick={() => setVisible(true)}>
                                <IoMdShareAlt className='text-gray-400 text-2xl' />
                            </Button>
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[425px]">
                            <DialogHeader>
                                <DialogTitle>Share Listing On</DialogTitle>

                            </DialogHeader>
                            <div className='flex justify-center items-center'>
                                <ShareOptions />
                            </div>


                        </DialogContent>
                    </Dialog>

                    <div>


                        <Button variant='secondary' disabled={loading} className={`${isSaved ? 'text-richblue-100' : 'text-gray-400'}`} onClick={SaveForLaterHandler}>
                            {
                                loading ? <span className="loader"></span> : <><FaHeart className='text-xl mr-2' />
                                    Save for later</>
                            }

                        </Button>
                    </div>




                </div>
                <div className='text-3xl font-bold m-6 font-inter capitalize'>
                    {data?.Listing?.name}
                </div>

                <div className='w-full z-10'>
                    <WPSGallery galleryImages={data?.Listing?.images} />
                </div>

                <div className='sm:grid flex flex-wrap-reverse sm:grid-cols-9 w-full p-8 gap-6'>

                    <div className='col-span-6 p-8 '>

                        <div>
                            <div className={isExpanded ? '' : 'truncate-multi-line'}>

                                <div dangerouslySetInnerHTML={{ __html: data?.Listing?.description }} />

                            </div>

                            <Button
                                variant='link'
                                onClick={() => setIsExpanded(!isExpanded)}
                            >
                                {isExpanded ? 'Read Less' : 'Read More'}
                            </Button>
                        </div>

                        {/* Features */}


                        <Separator className='w-full' />

                        <div className='text-2xl p-4 font-bold font-inter'>
                            Features
                        </div>
                        <Features vehicleInformation={data?.Listing?.vehicleFeatures?.vehicleInformation} />

                 
                       
                       <div className='my-6'>
                       <Tabs defaultValue="account" className="w-[400px]">
                            <TabsList className="grid w-full grid-cols-2 bg-transparent shadow-none">
                                <TabsTrigger className='shadow-none' value="account">standard features</TabsTrigger>
                                <TabsTrigger className='shadow-none' value="password">technical features</TabsTrigger>
                            </TabsList>
                            <TabsContent value="account">
                                { data?.Listing?.vehicleFeatures?.optionsFeature?.length > 0 && (
                                    <div className=" mt-8 relative">
                                        <table className="table-fixed font-inter min-w-full ">
                                 
                                            <tbody>
                                                {data?.Listing?.vehicleFeatures?.optionsFeature?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="px-4 py-2 text-sm font-medium ">
                                                            {item.feature}
                                                            technicalFeature                                             </td>
                                                        <td className="px-4 py-2  text-sm font-semibold ">
                                                            {item.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </TabsContent>
                            <TabsContent value="password">
                            {data?.Listing?.vehicleFeatures?.technicalFeature?.length > 0 && (
                                    <div className=" mt-8 relative">
                                        <table className="table-fixed font-inter min-w-full ">
                                    
                                            <tbody>
                                                {data?.Listing?.vehicleFeatures?.technicalFeature?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className="px-4 py-2 text-sm font-medium ">
                                                            {item.feature}
                                                        </td>
                                                        <td className="px-4 py-2  text-sm font-semibold ">
                                                            {item.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </TabsContent>
                        </Tabs>
                       </div>


                   



                        {/* auction history */}
                        <Separator className='w-full' />

                        <div className='text-2xl p-4 font-bold font-inter'>
                            Auction History
                        </div>
                        <AuctionHistoryTable bidHistory={data?.SortedBids} />

                    </div>

                    <div className='sm:col-span-3  font-inter'>
                        <div className='h-96 max-h-full w-full  sm:sticky sm:top-16 rounded-md border flex flex-col gap-4 p-6'>
                            <div>
                                <div className='text-2xl font-extrabold font-inter'>
                                    {highestBid ? formatPrice(highestBid) : formatPrice(data?.Listing?.highestBid !== undefined
                                        ? data?.Listing?.highestBid
                                        : (data?.Listing?.price || 0))}
                                </div>
                                <div className='text-muted-foreground'>
                                    Current Bid ({data?.Listing?.totalBids} bids)
                                </div>
                            </div>

                            <div className='bg-gray-100 grid px-2 rounded-md items-center grid-cols-2 justify-between p-2'>
                                <div>
                                    <div className='text-sm font-semibold'>
                                        {`${timeLeft.days}d ${timeLeft.hours}hrs ${timeLeft.minutes}min`}
                                    </div>
                                    <div className='text-xs text-muted-foreground'>
                                        Time Left
                                    </div>
                                </div>

                                <div>
                                    <div className='text-sm font-semibold'>
                                        {formatDate(data?.Listing?.endTime)}
                                    </div>
                                    <div className='text-xs text-muted-foreground'>
                                        Auction Ending
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='grid px-2 text-sm font-medium font-inter rounded-md items-center grid-cols-2 justify-between p-2'>
                                    <div className='text-muted-foreground'>
                                        Minimum Bid Amount
                                    </div>
                                    <div>
                                        {formatPrice(

                                            highestBid ? highestBid + (data?.Listing?.minimumBidDifference || 0) : (data?.Listing?.highestBid !== undefined
                                                ? data?.Listing?.highestBid + (data?.Listing?.minimumBidDifference || 0)
                                                : (data?.Listing?.price || 0) + (data?.Listing?.minimumBidDifference || 0))


                                        )}
                                    </div>
                                </div>
                                <div className='grid px-2 text-sm font-medium font-inter rounded-md items-center grid-cols-2 justify-between p-2'>
                                    <div className='text-muted-foreground'>
                                        Starting Bid
                                    </div>
                                    <div>
                                        {formatPrice(data?.Listing?.price || 0)}
                                    </div>
                                </div>
                            </div>



                            <div>
                                <Form {...form}>
                                    <form
                                        onSubmit={form.handleSubmit(onSubmit)}
                                        className="flex flex-col gap-4 w-full font-normal text-base justify-center items-center"
                                    >
                                        <FormField
                                            name="bidPrice"
                                            control={form.control}
                                            render={({ field }) => (
                                                <FormItem className="w-full flex flex-col gap-2 justify-center items-center">
                                                    <FormControl>
                                                        <Input
                                                            id="bidPrice"
                                                            type="number"
                                                            placeholder="Enter your bid"
                                                            {...field}
                                                            className="outline-none h-12 focus:outline-none border focus:border-0"
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <Button type="submit" disabled={bidLoading || data?.Listing?.status ==='past' || data?.Listing?.status ==='draft'} variant='btn' className='w-full py-2' size='lg'>

                                            {
                                                bidLoading ? <span className="loader"></span> : "Place Bid"
                                            }

                                        </Button>
                                    </form>
                                </Form>


                            </div>
                            <div className='z-10'>
                                <HoverCard>
                                    <HoverCardTrigger><Button variant='link'>Previous Bids Placed By You</Button></HoverCardTrigger>
                                    <HoverCardContent align='down'>
                                        <div>
                                            {userBidsData?.biddingHistory[0]?.bids ?
                                                userBidsData?.biddingHistory[0]?.bids?.map((bid) => {
                                                    return <div key={bid._id} className='grid px-2 text-sm font-medium font-inter rounded-md items-center grid-cols-2 justify-between p-2'>
                                                        <div>{formatPrice(bid.bidAmount)}</div>
                                                        <div>{formatDate(bid.bid_time)}</div>
                                                    </div>
                                                })
                                                :
                                                <div>
                                                    No Previous Bids
                                                </div>
                                            }
                                        </div>
                                    </HoverCardContent>
                                </HoverCard>
                            </div>
                        </div>
                    </div>
                </div>

            </MaxWidthWrapper>
        </>
    );
};

export default CarDetails;
