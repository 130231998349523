// src/notifications/NotificationService.js

import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from './firebaseConfig';
import { apiConnector } from './apiconnector';
// Request notification permission


export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    } else {
      console.warn('Notification permission not granted.');
    }
  } catch (error) {
    console.error('Error requesting notification permission', error);
  }
};

// Request FCM token
export const requestFirebaseToken = async (userId) => {
  try {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });

    if (token) {
      
      console.log("FCM Token:", token);

      // Send the token to your server for registration
      const res = await apiConnector('POST',process.env.REACT_APP_BASE_URL + '/api/register_token', { fcmtoken: token });

      console.log(res);

    } else {
      console.warn("No registration token available. Request permission to generate one.");
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
  }
};

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  const { title, body ,image } = payload.notification || {};
  if (title && body) {
    new Notification(title, {
      body,
      // Optional: You can include an icon or other options here
      icon: image ,
    });
  } else {
    console.warn('Invalid notification payload:', payload);
  }
});
