import React, { useState } from 'react';
import { Input } from '../../component/ui/input';
import { Button, buttonVariants } from '../../component/ui/button';
import { Icons } from "../../assests/Icons"
import {cn} from '../../lib/utils'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../component/ui/form';


import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "../../component/ui/select";


import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';



const formSchema = z.object({
    keyword: z.string().optional(),
    make: z.string().optional(),
    model: z.string().optional(),
    fromyear: z.string()
        .optional()
        .refine(value => value === undefined || !isNaN(Number(value)), {
            message: "From year must be a valid year.",
        }),
    tillyear: z.string()
        .optional()
        .refine(value => value === undefined || !isNaN(Number(value)), {
            message: "Till year must be a valid year.",
        }),
    price: z.string()
        .optional()
        .refine(value => value === undefined || !isNaN(Number(value)), {
            message: "Price must be a valid number.",
        }),
    mileage: z.string()
        .optional()
        .refine(value => value === undefined || !isNaN(Number(value)), {
            message: "Mileage must be a valid number.",
        }),
    bodyType: z.string().optional(),
}).refine((data) => {
    if (data.fromyear && data.tillyear) {
        return Number(data.tillyear) > Number(data.fromyear);
    }
    return true; // If either is not present, skip the comparison
}, {
    message: "Till year must be greater than from year.",
    path: ["tillyear"],
});




export const FilterButton = ({ children, onRemove }) => {
    return (
        <>
            <Button
                variant="ghost"
                className="bg-richblue-50 flex gap-2 pr-[5px] hover:bg-richblue-50 justify-between items-center rounded-full"
            >
                <p>{children}</p>
                <div className='bg-richblue-100 h-8 w-8 flex justify-center hover:bg-richblue-100/90 cursor-pointer items-center rounded-full' onClick={onRemove}>
                    <Icons.cross className="text-white h-6 w-6 " />
                </div>
            </Button>
        </>
    );
};


const Filter = ({className}) => {

    const [fromYearSelected, setFromYearSelected] = useState(1986);

    // Use useForm hook
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            keyword: "",
            make: "",
            model: "",
            fromyear: String(fromYearSelected),
            tillyear: String(fromYearSelected + 1),
            price: '',
            mileage: '',
            bodyType: "",
        },
    });


    const currentYear = new Date().getFullYear();

    const years = Array.from({ length: currentYear - 1986 + 1 }, (_, i) => 1986 + i);
    // Function to handle form submission
    const onSubmit = (data) => {
        console.log(data); // This will log all form values
    };

    


    return (

        <div className= {cn('h-full',className)} >

                <div className="min-h-full h-full flex col-span-1 items-center">
                    <div className="  p-2 w-[350px]   flex flex-col justify-center items-center">
                        <Form {...form}>
                            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                                {/* Keyword */}
                                <FormField
                                    control={form.control}
                                    name="keyword"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Keyword</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Write your keyword" className="w-[280px] bg-richblue-50" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Make */}
                                <FormField
                                    control={form.control}
                                    name="make"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Make</FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange}>
                                                    <SelectTrigger className="w-[280px] bg-richblue-50">
                                                        <SelectValue placeholder="Select a maker" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            <SelectLabel>Makers</SelectLabel>
                                                            <SelectItem value="Toyota">Toyota</SelectItem>
                                                            <SelectItem value="Honda">Honda</SelectItem>
                                                            <SelectItem value="Ford">Ford</SelectItem>
                                                            {/* Add other makes here */}
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Model */}
                                <FormField
                                    control={form.control}
                                    name="model"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Model</FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange}>
                                                    <SelectTrigger className="w-[280px] bg-richblue-50">
                                                        <SelectValue placeholder="Select a model" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            <SelectLabel>Models</SelectLabel>
                                                            <SelectItem value="Corolla">Corolla</SelectItem>
                                                            <SelectItem value="Civic">Civic</SelectItem>
                                                            <SelectItem value="Mustang">Mustang</SelectItem>
                                                            {/* Add other models here */}
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <div className="grid grid-cols-2 gap-2">
                                    {/* From Year */}
                                    <FormField
                                        control={form.control}
                                        name="fromYear"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>From</FormLabel>
                                                <FormControl>
                                                    <Select
                                                        onValueChange={(value) => {
                                                            field.onChange(value);
                                                            setFromYearSelected(Number(value)); // Update fromYear state
                                                        }}
                                                        defaultValue={field.value}
                                                    >
                                                        <SelectTrigger className="bg-richblue-50">
                                                            <SelectValue placeholder="Select a year" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                <SelectLabel>From Year</SelectLabel>
                                                                {years.map((year) => (
                                                                    <SelectItem key={year} value={String(year)}>
                                                                        {year}
                                                                    </SelectItem>
                                                                ))}
                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    {/* Till Year */}
                                    <FormField
                                        control={form.control}
                                        name="tillYear"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Till</FormLabel>
                                                <FormControl>
                                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                        <SelectTrigger className="bg-richblue-50">
                                                            <SelectValue placeholder="Select a year" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                <SelectLabel>Till Year</SelectLabel>
                                                                {years
                                                                    .filter(year => year > fromYearSelected) // Only show years greater than fromYearSelected
                                                                    .map((year) => (
                                                                        <SelectItem key={year} value={String(year)}>
                                                                            {year}
                                                                        </SelectItem>
                                                                    ))}
                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </div>



                                {/* Price */}
                                <FormField
                                    control={form.control}
                                    name="price"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Price Range</FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange}>
                                                    <SelectTrigger className="w-[280px] bg-richblue-50">
                                                        <SelectValue placeholder="Select a price range" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            <SelectLabel>Price Range</SelectLabel>
                                                            <SelectItem value="0-3000">$0 - $3000</SelectItem>
                                                            <SelectItem value="3000-5000">$3000 - $5000</SelectItem>
                                                            <SelectItem value="5000-10000">$5000 - $10000</SelectItem>
                                                            <SelectItem value="10000-15000">$10000 - $15000</SelectItem>
                                                            <SelectItem value="15000-20000">$15000 - $20000</SelectItem>
                                                            <SelectItem value=">20000">More than $20000</SelectItem>
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Mileage */}
                                <FormField
                                    control={form.control}
                                    name="mileage"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Mileage Range (in km)</FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange}>
                                                    <SelectTrigger className="w-[280px] bg-richblue-50">
                                                        <SelectValue placeholder="Select a mileage range" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            <SelectLabel>Mileage Range</SelectLabel>
                                                            <SelectItem value="0-10000">0 - 10,000 km</SelectItem>
                                                            <SelectItem value="10000-30000">10,000 - 30,000 km</SelectItem>
                                                            <SelectItem value="30000-50000">30,000 - 50,000 km</SelectItem>
                                                            <SelectItem value="50000-100000">50,000 - 100,000 km</SelectItem>
                                                            <SelectItem value=">100000">More than 100,000 km</SelectItem>
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Body Type */}
                                <FormField
                                    control={form.control}
                                    name="bodyType"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Body Type</FormLabel>
                                            <FormControl>
                                                <Select onValueChange={field.onChange}>
                                                    <SelectTrigger className="w-[280px] bg-richblue-50">
                                                        <SelectValue placeholder="Select a body type" />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            <SelectLabel>Body Type</SelectLabel>
                                                            <SelectItem value="sedan">Sedan</SelectItem>
                                                            <SelectItem value="hatchback">Hatchback</SelectItem>
                                                            <SelectItem value="suv">SUV</SelectItem>
                                                            <SelectItem value="coupe">Coupe</SelectItem>
                                                            <SelectItem value="convertible">Convertible</SelectItem>
                                                            <SelectItem value="truck">Truck</SelectItem>
                                                            <SelectItem value="van">Van</SelectItem>
                                                            <SelectItem value="wagon">Wagon</SelectItem>
                                                            <SelectItem value="crossover">Crossover</SelectItem>
                                                            <SelectItem value="roadster">Roadster</SelectItem>
                                                            <SelectItem value="minivan">Minivan</SelectItem>
                                                            <SelectItem value="pickup">Pickup</SelectItem>
                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {/* Submit Button */}
                                <Button type="submit">Apply</Button>
                            </form>
                        </Form>
                    </div>
                </div>

             

            </div>


    );
};

export default Filter;
