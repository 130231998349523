import React from 'react'
import Dashboard from './Dashboard'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../component/ui/button';
import MaxWidthWrapper from '../../component/MaxWidthWrapper';
import {Link} from 'react-router-dom'


const AdminDashboard = () => {
    const { user } = useSelector((state) => state.profile);

    return (
        < Dashboard >

            <header className="relative ">
           

                    <div className="flex h-16 p-2 justify-between items-center">
                        <div className="ml-4 text-3xl font-inter font-bold flex lg:ml-0">
                            <Link href="/">
                                Dashboard
                            </Link>
                        </div>

              
                    </div>
            </header>


            <div className='w-full h-48 bg-richblue-100 rounded-xl  '>

                <div className='p-6 flex flex-col space-y-2'>

                    <div className='text-white text-2xl font-semibold'>
                        Hi {user?.username}.
                    </div>
                    <div className='text-sm text-white/70'>
                        Welcome to our car auction hub, where finding your perfect vehicle is a thrilling journey! Dive into a vast selection of cars, place your bids, and make your dream ride a reality.
                    </div>
                    <div>
                        <Button variant='white'>
                            Browse auctions
                        </Button>
                    </div>
                </div>



            </div>
        </Dashboard>
    )
}

export default AdminDashboard;