import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    istoken: false,
}

const profileSlice = createSlice({
    name: "profile",
    initialState: initialState,

    reducers: {
        setUser(state, value) {
            state.user = value.payload

        },
        setIstoken: (state, action) => {
            state.istoken = action.payload;
        },

    },
}
)
export const { setUser, setIstoken } = profileSlice.actions;

export default profileSlice.reducer;  