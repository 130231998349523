import React, { useState, useEffect } from 'react';
import { Timer } from './CarCards'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const CarBodyCard = ({ car, endTime }) => {

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endTime));

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft(endTime));
        }, 1000);

        // Clear the timer when the component unmounts
        return () => clearInterval(timer);
    }, [endTime]);

    function calculateTimeLeft(endTime) {
        const difference = new Date(endTime) - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            timeLeft = { expired: true };
        }

        return timeLeft;
    }

    return (
        <div className='grid grid-cols-2 rounded-lg px-2 justify-between items-center w-full shadow-lg border'>
            <div className=''>
                <Swiper

                    pagination={{ clickable: true }}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Pagination, Mousewheel, Keyboard]}
                    className="mySwiper flex justify-center items-center"
                >
                    {
                        car.images?.map((card, index) => (
                            <SwiperSlide key={index} className="flex justify-center items-center">
                                <div className="flex justify-center items-center h-48">
                                    <img
                                        src={card}
                                        alt={`car-${index}`}
                                        className="h-30 object-cover  rounded-md"
                                    />
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>

            <div className=' grid grid-rows-8'>
                <div className='font-semibold '>
                    {car.name}
                </div>
                <div className='text-muted-foreground text-sm'>
                    {car.description}
                </div>
                <div className='text-lg font-semibold'>
                    {car.price}
                </div>

                <div className='text-sm text-muted-foreground'>
                    {car.mileage}
                </div>

                <div>
                    <Timer timeLeft={timeLeft} />

                </div>
            </div>
        </div>
    )
}

export default CarBodyCard;