import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MaxWidthWrapper from './MaxWidthWrapper'
import { buttonVariants, Button } from './ui/button'
import { Icons } from '../assests/Icons'
import { useLocation } from "react-router-dom"
import Navitems from './Navitems'
import { FaChevronDown } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { setToken } from '../slices/authSlice'
import { setIstoken, setUser } from '../slices/profileSlice'
import { RxDashboard } from "react-icons/rx";
import { TfiHelpAlt } from "react-icons/tfi";
import { cn } from "../lib/utils"
import Cart from './Cart'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "./ui/dropdown-menu"




const Dropdown = ({ user, handleLogout }) => {
    return <DropdownMenu>
        <DropdownMenuTrigger asChild>

            <Button size='sm' variant="outline" className='rounded-full pl-0 font-inter font-normal'>

                {user && user?.image ? <img src={user?.image} fill className='w-8 rounded-full  h-8 mr-2 object-cover object-center' /> : <Icons.photo className="w-8 h-8 mr-2" />

                }



                <FaChevronDown className='text-gray-500 ' />

            </Button>
        </DropdownMenuTrigger>

        <DropdownMenuContent className="w-56 font-inter rounded-lg font-normal ">

            <DropdownMenuLabel className="font-inter text-sm font-medium items-center flex ">
                {user && user?.image ? <img src={user?.image} fill className='w-10 rounded-full h-10 mr-2 object-cover object-center' /> : <Icons.photo className="w-10 h-10 mr-2" />

                }
                {user?.username || 'N/A'}
            </DropdownMenuLabel>

            <DropdownMenuSeparator />

            <DropdownMenuGroup>
                <Link to={user?.accountType === 'admin' ? '/admin/dashboard' : '/dashboard'}>
                    <DropdownMenuItem className="font-inter font-normal">
                        <RxDashboard className='mx-2 text-lg font-inter font-normal' />

                        Dashboard

                    </DropdownMenuItem>
                </Link>
                <DropdownMenuItem className="font-inter font-normal">
                    < TfiHelpAlt className='mx-2 text-lg font-inter font-normal' />

                    Help

                </DropdownMenuItem>

            </DropdownMenuGroup>

            <DropdownMenuSeparator />


            <DropdownMenuItem className='font-inter font-normal ' onClick={handleLogout}  >

                < IoIosLogOut className='mx-2 text-lg font-inter font-normal' />
                Log out


            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
}

function Navbar({className}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();


    const { istoken, user } = useSelector((state) => state.profile) || {};

    const handleLogout = () => {
        // Clear token from Redux state
        dispatch(setToken(null));
        dispatch(setIstoken(false));
        dispatch(setUser(null))
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate('/sign-in');
    };

    const location = useLocation();

    const nav = [
        {
            name: "Home",
            pathname: "/",
            location: location.pathname
        },
        {
            name: "Browse Auctions",
            pathname: "/browse_auctions",
            location: location.pathname
        }, {
            name: "About Us",
            pathname: "/about_us",
            location: location.pathname
        },
        {
            name: "Contact Us",
            pathname: "/contact_us",
            location: location.pathname
        },
        {
            name: "Dashboard",
            pathname: user?.accountType === 'Admin' ? '/admin/dashboard' : '/dashboard',
            location: location.pathname
        }
    ]
    return (
        <div className={cn('bg-white fixed z-50 top-0 inset-x-0 h-16 ',className)}>
            <header className='relative bg-white '>
                <MaxWidthWrapper>
                    <div className='flex h-16 items-center'>
                        {/* todo: mobile nav */}


                        <div className='ml-4 flex lg:ml-0'>
                            <Link href='/'>
                                <Icons.logo className='h-10 w-10 ' />
                            </Link>
                        </div>

                        <div className='ml-auto flex items-center '>
                            <div className='hidden z-50 lg:ml-8 lg:block lg:self-stretch'>
                                <div className='flex space-x-8 justify-center items-center'>
                                    {
                                        istoken ? (
                                            <>
                                                {nav.map((item) => (
                                                    <Navitems key={item.name} location={item.location} pathname={item.pathname}>
                                                        {item.name}
                                                    </Navitems>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {nav.map((item) => {
                                                    if (item.name !== "Dashboard") {
                                                        return (
                                                            <Navitems key={item.name} location={item.location} pathname={item.pathname}>
                                                                {item.name}
                                                            </Navitems>
                                                        );
                                                    }
                                                    return null; 
                                                })}
                                            </>
                                        )
                                    }

                                </div>

                            </div>
                        </div>


                        <div className='ml-auto flex items-center'>
                            <div className='hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6'>

                                {istoken ? null : (<Link to='/sign-in' className={buttonVariants({
                                    variant: 'outline2',
                                    size: 'sm'
                                })}>Log in</Link>)

                                }

                                {istoken ? null : <span className='h-6 w-px bg-gray-200'
                                    aria-hidden='true'
                                />
                                }

                                {istoken ? <p></p> : <Link to='/sendotp' className={buttonVariants({
                                    variant: 'btn'
                                    ,
                                    size: 'sm'
                                })}>Sign up</Link>}


                                {istoken ? <Dropdown user={user} handleLogout={handleLogout} /> : null
                                }

                                {istoken ? <span className='h-6 w-px bg-gray-200'
                                    aria-hidden='true'
                                /> : null
                                }



                                <div className='ml-4 flow-root lg:ml-4'>
                                    <Cart />

                                </div>
                            </div>

                        </div>

                    </div>
                </MaxWidthWrapper>
            </header>


        </div>
    )
}

export default Navbar

{/* <Link to={user.accountType === "User" ? '/dashboard': '/admin/dashboard'} className={buttonVariants({
                                    variant: 'btn'
                                    ,
                                    size: 'sm'
                                })}>Dashboard</Link> */}

// {istoken ? <div onClick={handleLogout} className={buttonVariants({
//     variant: 'btn'
//     ,
//     size: 'sm',
//     className: 'cursor-pointer'

// })}>Log out <  IoIosLogOut className='ml-2' /></div> : null
// }
