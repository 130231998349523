import "./App.css";
import { Routes, Route } from "react-router-dom";

// public routes *************
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Signin from "./pages/Signin";
import Page from "./pages/Page";
import About from "./pages/About";
import Contact from "./pages/Contact";
import BrowseAuctions from "./pages/BrowseAuctions";
// import Profile from "./pages/Profile"

// protected routes **************
import ProtectedRoute from "./components/user/ProtectedRoute";
import ProtectedRouteAdmin from "./components/admin/ProtectedRouteAdmin";

// user routes **************
import UserDashboard from "./components/user/UserDashboard";
import Saved from "./components/user/Saved";
import Settings from "./components/user/Settings";
import Auctions from "./components/user/Auctions/Auctions";
import AuctionCar from "./components/user/Auctions/AuctionCar";

// admin routes **************
import AdminDashboard from "./components/admin/AdminDashboard";
import NewListing from "./components/admin/new_listing/index";
import AllListing from "./components/admin/all_listing/index";
import CompleteListing from "./components/admin/all_listing/CompleteListing";

// public routes
import CarDetails from "./components/home/CarDetails";

function App() {
  
  return (
    <div className="w-screen ">
      <Routes>
        {/* public routes */}
        <Route path="/" element={<Home />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/sign-in" element={<Login />} />
        <Route path="/sendotp" element={<Signin />} />
        <Route path="/change_password" element={<Signup />} />
        <Route path="/forgot_password" element={<Signin />} />
        <Route path="/about_us" element={<About />} />
        <Route path="/contact_us" element={<Contact />} />
        <Route path="/browse_auctions" element={<BrowseAuctions />} />
        <Route
          path="/browse_auctions/car_details/:id"
          element={<CarDetails />}
        />

        {/* admin-route */}
        <Route element={<ProtectedRouteAdmin />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/new_listing/:params" element={<NewListing />} />
          <Route path="/admin/all_listings" element={<AllListing />} />
          <Route
            path="/admin/all_listings/draft/:params/:id"
            element={<CompleteListing />}
          />
        </Route>

        {/* user-route  */}
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/dashboard/saved" element={<Saved />} />
          <Route path="/dashboard/Settings" element={<Settings />} />
          <Route path="/dashboard/Auctions" element={<Auctions />} />
          <Route path="/dashboard/Auctions/:id" element={<AuctionCar />} />
        </Route>

        <Route path="/profile" element={<Login />} />

        <Route path="*" element={<Page />} />
      </Routes>
    </div>
  );
}

export default App;
