import React, { useEffect } from 'react';
import { Sheet, SheetContent, SheetHeader, SheetTrigger, SheetTitle, SheetFooter } from "./ui/sheet"
import { Separator } from "./ui/separator"
import { formatPrice } from "../lib/utils"
import { Link } from 'react-router-dom'
import { MdNotificationsNone } from "react-icons/md";
import Img4 from '../assests/img4.png'
import { buttonVariants } from "./ui/button"
import { FiExternalLink } from "react-icons/fi";
import { useSelector, useDispatch } from 'react-redux'

const Cart = () => {
    const { notifications, unreadCount } = useSelector((state) => state.socket)
    // console.log(notifications, unreadCount)
    const itemcount = unreadCount
    const fee = 1
    return <Sheet>
        <SheetTrigger className="group font-inter relative -m-2 flex items-center p-2">
            <MdNotificationsNone
                aria-hidden='true'
                className="h-6 w-6 flex-shrink-0 text-gray-600 group-hover:text-gray-500" />

            <span className="ml-2 absolute top-1 right-1 text-xs bg-richblue-100 w-4 h-4 border-2 border-white flex justify-center items-center rounded-md   font-medium text-white group-hover:text-whtie/90">
                {unreadCount}
            </span>

        </SheetTrigger>


        <SheetContent side='right' className="flex w-96 flex-col font-inter pr-4 sm:max-w-lg">
            <SheetHeader className="space-y-2.5 pr-6">
                <SheetTitle > Notifications ({unreadCount})</SheetTitle>
            </SheetHeader>

            {notifications.length > 0 ? (<>
                <div className="flex w-full flex-col text-indigo-500 text-sm  pr-6">
                    Recent Notifications
                </div>
                <div className="space-y-4 pr-6">
                    <Separator />
                    <div className="space-y-1.5 pr-6">
                        {
                            notifications?.map((notify) => {
                                return (
                                    <Link to={`/browse_auctions/car_details/${notify.carId}`} key={notify.title}>
                                        <div className="flex flex-col justify-center border rounded-md ">
                                            <div className="font-semibold">{`New Bid on ${notify.title}`}</div>
                                            <div className="flex items-center mt-2 space-x-2">
                                                <div className="h-12 w-12 rounded-full overflow-hidden">
                                                    <img
                                                        src={notify?.image}
                                                        alt="Car image"
                                                        className="object-cover"
                                                    />
                                                </div>
                                                <div>
                                                    New highest bid placed on {notify.title} for <strong>{formatPrice(notify.bidAmount)}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                );
                            })
                        }


                    </div>

                    <SheetFooter>
                        <SheetTrigger asChild>
                            <Link href="/cart" className={buttonVariants({
                                variant: 'outline',
                                className: 'w-full'
                            })} >
                                Mark as Read
                            </Link>
                        </SheetTrigger>
                    </SheetFooter>
                </div>
            </>) : (<>
                <div className="flex h-full flex-col items-center justify-center space-y-1">
                    <div className="flex justify-center items-center mb-4 h-48 w-48 text-muted-foreground">
                        <img src={Img4}
                            fill
                            alt="empty notifications" />
                    </div>
                    <div className="text-xl font-semibold">No Notification Found</div>
                    <SheetTrigger asChild>
                        <Link to='/browse_auctions' className={buttonVariants({
                            variant: 'link',
                            size: 'sm',
                            className: 'text-sm text-muted-foreground underline cursor-pointer'
                        })}>
                            Browse acutions to get started
                            <FiExternalLink className="ml-2" />

                        </Link>
                    </SheetTrigger>
                </div>

            </>)}
        </SheetContent>

    </Sheet>


}

export default Cart; 