import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import MaxWidthWrapper from "../../../component/MaxWidthWrapper";
import { Button } from "../../../component/ui/button";
import { Separator } from "../../../component/ui/separator";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../component/ui/form";
import { Input } from "../../../component/ui/input";
import { Textarea } from '../../../component/ui/textarea'
import { useSelector, useDispatch } from 'react-redux'
import { setNewListing } from "../../../slices/listingSlice";
import { useCreateListingMutation } from '../../../slices/apiSlices/carListingApiSlice'
import { toast } from '../../../component/ui/use-toast'
import { useNavigate,useParams } from "react-router-dom";


// Zod schema for validation
const vehicleSchema = z.object({
  name: z.string().min(1, "Name is required"),
  description: z.string().min(1, "Description is required"),
  price: z.string().min(1, "Price is required and must be a number"),
  startTime: z.string().min(1, "Start Time is required").refine(value => {
    const today = new Date();
    const startTimeDate = new Date(value);
    return !isNaN(startTimeDate.getTime()) && startTimeDate >= today;
  }, "Start Time cannot be in the past"),
  endTime: z.string().min(1, "End Time is required").refine(value => {
    const endTimeDate = new Date(value);
    return !isNaN(endTimeDate.getTime());
  }, "End Time must be a valid date"),
  minimumBidDifference: z.string().default("100"),
});



const VehicleDetails = (newListing) => {
  const editor = useRef(null);

  const { id } = useParams();

  // JoditEditor config
  const config = useMemo(() => ({
    readonly: false,
    placeholder: 'Start typing...',
    toolbar: true,
    toolbarSticky: false, // Sticky toolbar
    toolbarButtonSize: 'middle', // Set button size
    removeButtons: [
      'print', 'about', 'fullsize', 'source', 'image', 'video', 'file',
      'cut', 'copy', 'paste', 'selectall', 'redo', 'undo'
    ], // Remove extra buttons
    height: 300,
    width: 384,
    fontFamily:'arial',
    buttons: ['bold', 'italic', 'underline', 'link', 'ul', 'ol'],
  }), []);

  const dispatch = useDispatch();
  const [createListing, { isLoading, isSuccess, isError, error, data }] = useCreateListingMutation();
  const navigate = useNavigate()


  // const { newListing } = useSelector(state => state.listing)

  // React Hook Form setup with Zod validation
  const form = useForm({
    resolver: zodResolver(vehicleSchema),
    defaultValues: {
      name: newListing?.data?.name || "",
      description: newListing?.data?.description|| "",
      price: String(newListing?.data?.price) || "",
      startTime: newListing?.data?.startTime ? new Date(newListing.data.startTime).toISOString().slice(0, -8) : "",
      endTime: newListing?.data?.endTime ? new Date(newListing.data.endTime).toISOString().slice(0, -8) : "",
      minimumBidDifference: String(newListing?.data?.minimumBidDifference) || "100",
    }
  });


  // Form submission handler
  const onSubmit = async (data) => {

    console.log(data)

    if (id) {
      data.id = id;
    }

    // Validate time fields
    if (data.endTime && data.startTime && new Date(data.endTime) <= new Date(data.startTime)) {
      form.setError('endTime', {
        type: 'manual',
        message: 'End Time must be later than Start Time',
      });
      return;
    }

    try {


      const response = await createListing({ formData: data , step: '1' }).unwrap();

      // Check if the response was successful
      toast({
        title: "Car listing created successfully",
        description: "See the listing in draft in all listings",
      });
      form.reset()

      navigate("/admin/all_listings");

      // console.log(response);

    } catch (err) {
      // Handle errors and display a toast notification
      toast({
        title: "Failed to create listing",
        description: err?.data.message || data.message || 'An error occurred while creating the listing. Please try again later.',
        variant: 'destructive',
      });

      console.error(err);
    }
  };


  return (
    <div className="my-10">
      <div className="flex w-full justify-center items-center flex-col gap-2">
        <div className="text-3xl font-bold font-inter">Set Vehicle Details</div>
        <Separator className="mt-2 w-80" />
      </div>

      <div className="flex w-full my-6 justify-center items-center flex-col gap-2">
        {/* Form */}
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4 font-normal text-base justify-center items-center"
          >
            {/* Name Field */}
            <FormField
              name="name"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Vehicle Name</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter vehicle name"
                      className="border-[1px] ring-0 rounded-md p-6 w-96 focus:outline-0"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Description Field */}
            <FormField name="description"
             control={form.control} 
             
             render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <JoditEditor
                    ref={editor}
                    value={field.value || ''} 
                    config={config}
                    tabIndex={1}
                    onBlur={newContent => field.onChange(newContent)}  
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )} />

            {/* Price Field */}
            <FormField
              name="price"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Price</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Enter price"
                      className="border-[1px] ring-0 rounded-md p-6 w-96 focus:outline-0"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* Start Time Field */}
            <FormField
              name="startTime"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Start Time</FormLabel>
                  <FormControl>
                    <Input
                      type="datetime-local"
                      min={new Date().toISOString().slice(0, -8)}
                      className="border-[1px] ring-0 rounded-md p-6 w-96 focus:outline-0"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {/* End Time Field */}
            <FormField
              name="endTime"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>End Time</FormLabel>
                  <FormControl>
                    <Input
                      type="datetime-local"
                      className="border-[1px] ring-0 rounded-md p-6 w-96 focus:outline-0"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Minimum Bid Difference Field */}
            <FormField
              name="minimumBidDifference"
              control={form.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Minimum Bid Difference</FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="100"
                      className="border-[1px] ring-0 rounded-md p-6 w-96 focus:outline-0"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-2 w-96 gap-10 mt-8">
              {/* <Button variant="secondary">Back</Button> */}
              <Button type="submit" disabled={isLoading}>
                {isLoading ? <span className="loader"></span> : "Next"}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};

export default VehicleDetails;
