import { createSlice } from '@reduxjs/toolkit';
import { getDatabase, ref, onValue } from 'firebase/database';
import { database } from '../service/firebaseConfig'; // Adjust the path as needed

const bidSlice = createSlice({
  name: 'bid',
  initialState: {
    highestBid: null,
    loading: true,
    error: null,
    cars: {}, 
  },
  reducers: {
    setHighestBid(state, action) {
      state.highestBid = action.payload;
      state.loading = false;
    },
    setCarHighestBid(state, action) {
      const { carId, highestBid } = action.payload;
      state.cars[carId] = highestBid;
      state.loading = false;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
      state.loading = false;
    }
  }
});

export const listenToHighestBid = ({carId}) => (dispatch) => {
  if (!carId) {
    dispatch(setError('Invalid car ID'));
    return;
  }

  const carBidRef = ref(database, `cars/${carId}/highestBid`);

  dispatch(setLoading(true));

  const unsubscribe = onValue(carBidRef, (snapshot) => {
    const data = snapshot.val();
    dispatch(setHighestBid(data));
  }, (error) => {
    dispatch(setError(error.message));
  });

  return unsubscribe; // Return unsubscribe function to clean up listener
};


// export const listenToAllCarBids = () => (dispatch) => {
//   const carsRef = ref(database, 'cars'); 
  
//   dispatch(setLoading(true));

//   const unsubscribe = onValue(carsRef, (snapshot) => {
//     const carsData = snapshot.val();
//     if (carsData) {
//       Object.entries(carsData).forEach(([carId, carData]) => {
//         if (carData.highestBid !== undefined) {
//           dispatch(setCarHighestBid({ carId, highestBid: carData.highestBid }));
//         }
//       });
//     } else {
//       dispatch(setError('No cars found'));
//     }
//   }, (error) => {
//     dispatch(setError(error.message));
//   });

//   return unsubscribe; // Return unsubscribe function to clean up listener
// };



export default bidSlice.reducer;
export const { setHighestBid, setLoading, setCarHighestBid , setError } = bidSlice.actions;
