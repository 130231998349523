import { Suspense } from 'react';
import MaxWidthWrapper from '../../component/MaxWidthWrapper';
import Navbar from '../../component/Navbar';
import Sidebar, { SidebarItem } from '../../component/Sidebar';
import { LayoutDashboard, BarChart3, Boxes, Package, Receipt, } from 'lucide-react';
import { TfiHelpAlt } from "react-icons/tfi";
import { FaRegUserCircle } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../component/ui/button'
import { useLocation } from 'react-router-dom';
import ErrorBoundry from '../../ErrorBoundry';
import { RiSettings2Line, RiAuctionLine, RiMoneyPoundBoxFill } from "react-icons/ri";
import { BookMarked } from 'lucide-react';


const checkPathname = (location, link) => {
  return location.pathname === link;
};



const Dashboard = ({ children }) => {

  const { user } = useSelector((state) => state.profile)

  const location = useLocation();



  const sidebarItems = [
    { icon: <LayoutDashboard size={20} />, text: 'Dashboard', link: '/dashboard' },
    { icon: <RiAuctionLine size={20} />, text: 'Auctions', link: '/dashboard/auctions' },
    { icon: <BookMarked size={20} />, text: 'Saved', link: '/dashboard/saved', alert: true },
    // { icon: <RiMoneyPoundBoxFill size={20} />, text: 'All Listings', link: '/all_listings' },
    // { icon: <FaRegUserCircle size={20} />, text: 'Users', link: '/users' },
    // { icon: <BarChart3 size={20} />, text: 'Statistics', link: '/statistics' },
    { icon: <RiSettings2Line size={20} />, text: 'Settings', link: '/dashboard/settings' },
    { icon: <TfiHelpAlt size={20} />, text: 'Help', link: '/help' },
  ];

  return (
    <div className="min-h-screen font-inter">
      {/* Navbar */}

      <MaxWidthWrapper>
        <Navbar />
      </MaxWidthWrapper>


      <div className="flex">

        {/* Sidebar */}
        <div className="sticky top-0 pl-2 h-screen">
          <Sidebar>
            {sidebarItems.map((item, index) => (
              <SidebarItem
                key={index}
                icon={item.icon}
                text={item.text}
                link={item.link}
                active={checkPathname(location, item.link)}
                alert={item.alert}
              />
            ))}
          </Sidebar>
        </div>

        {/* Main Content */}

        <ErrorBoundry>
          <div className="flex-1 flex-col pt-24  rounded-xl  p-4 px-12 ">
            <Suspense fallback={<div>Loading...</div>}>
              {children}
            </Suspense>
          </div>
        </ErrorBoundry>



      </div>
    </div>
  );
};

export default Dashboard;


